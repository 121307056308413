<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('设备类型管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm'
          v-if="user_role['equipment_model/add']" size="small">{{$t('新增产品类型')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <div style="margin:15px 0; width: 250px;">
        <el-input :placeholder="$t('请输入关键字')" v-model="name" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-input>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column label="ID" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('型号名称')" prop="model_name">
        </el-table-column>
        <el-table-column :label="$t('功率')">
          <template slot-scope="scope">
             <span>{{scope.row.power}}KW</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('生产日期')" prop="production_date">
        </el-table-column>
        <el-table-column :label="$t('录入时间')" prop="created_at">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
           <!-- <el-button type="text" size="small" @click="getDetail(scope)">查看详情</el-button> -->
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['equipment_model/edit']" size="small">{{$t('修改类型')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['equipment_model/delete']" size="small">{{$t('删除类型')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('添加产品类型')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" label-position="top" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('型号名称')" required prop="model_name" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.model_name" clearable></el-input>
            </el-form-item>
          </el-col><!-- /型号名称ID -->
          <el-col :span="12">
            <el-form-item :label="$t('功率')" required prop="power" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.power" :placeholder="$t('单位/KW')" clearable></el-input>
            </el-form-item>
          </el-col><!-- /功率 -->
          <el-col :span="12">
            <el-form-item :label="$t('生产日期')" required prop="production_date" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-date-picker
                    v-model="addform.production_date"
                    type="datetime"
                    align="right"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    >
                  </el-date-picker>
            </el-form-item>
          </el-col><!-- /生产日期 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改产品类型')" :visible.sync="editAdmin" width="80%">
      <el-row :gutter="20">
       <el-form :model="editform" label-position="top" label-width="100px" ref="editform">
         <el-col :span="12">
           <el-form-item label="ID" required prop="ID">
             <el-input v-model="editform.id" disabled></el-input>
           </el-form-item>
         </el-col><!-- /ID -->
         <el-col :span="12">
           <el-form-item :label="$t('型号名称')" required prop="model_name"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-input v-model="editform.model_name" clearable></el-input>
           </el-form-item>
         </el-col><!-- /型号名称ID -->
         <el-col :span="12">
           <el-form-item :label="$t('功率')" required prop="power"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-input v-model="editform.power" :placeholder="$t('单位/KW')" clearable></el-input>
           </el-form-item>
         </el-col><!-- /功率 -->
         <el-col :span="12">
           <el-form-item :label="$t('生产日期')" required prop="production_date"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-date-picker
                   v-model="editform.production_date"
                   type="datetime"
                   align="right"
                   value-format="yyyy-MM-dd hh:mm:ss"
                   >
                 </el-date-picker>
           </el-form-item>
         </el-col><!-- /生产日期 -->
       </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('产品详情')" :visible.sync="Detail" width="80%">
      <el-row :gutter="20">
        <el-form v-model="DetailForm" label-position="top" label-width="100px" ref="DetailForm">
          <el-col :span="12">
            <el-form-item :label="$t('客户ID')" prop="oc_id">
              <el-input v-model="DetailForm.oc_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" prop="kehu_name">
              <el-input v-model="DetailForm.kehu_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品ID')" prop="equipment_id">
              <el-input v-model="DetailForm.equipment_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品ID -->
          <el-col :span="12">
            <el-form-item :label="$t('产品名称')" prop="equipment_name">
              <el-input v-model="DetailForm.equipment_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品类型id')" prop="em_id">
              <el-input v-model="DetailForm.em_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品类型id -->
          <el-col :span="12">
            <el-form-item :label="$t('质保到期时间')" prop="warranty_end_time">
              <el-input v-model="DetailForm.warranty_end_time" disabled></el-input>
            </el-form-item>
          </el-col><!-- /质保到期时间 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品型号')" prop="model_name">
              <el-input v-model="DetailForm.model_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品型号 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品功率')" prop="power">
              <el-input v-model="DetailForm.power" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品功率 -->
          <el-col :span="12">
            <el-form-item :label="$t('归属区域')" prop="or_id">
              <el-input v-model="DetailForm.or_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /归属区域 -->
          <el-col :span="12">
            <el-form-item :label="$t('区域名称')" prop="rname">
              <el-input v-model="DetailForm.rname" disabled></el-input>
            </el-form-item>
          </el-col><!-- /区域名称 -->
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="DetailForm.remarks" disabled></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail= false">{{$t('关闭')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'producType',
  data () {
    return {
      addAdmin: false,
      editAdmin: false,
      UntreatedData: [],
      name: '',
      addform: {
        model_name: '',
        power: '',
        production_date: ''
      },
      editform: {
        id: '',
        model_name: '',
        power: '',
        production_date: ''
      },
      DetailForm: {
        model_name: '',
        power: '',
        production_date: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      user_role: {}
    }
  },
  created () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  mounted () {

  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        // console.log(item)
        this.editform = this.$common.forData2(item.row,this.editform)
        this.editAdmin = true
      } else {
        this.addAdmin = true
      }
    },
    // 获取客户区域列表
    getUserList (page, report) {
      let params = {
        page:page?page:this.Page.page,
        name:this.name
      }
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'equipment_model/lists')
        return
      }
      this.$axios.post('equipment_model/lists',params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        }
      }).catch((error) => console.log(error))
    },
    // 添加
    addMin () {
      let params = this.$common.forData(this.addform)
      this.$axios.post('equipment_model/add', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.addAdmin = false
          var that = this
          // 清空表单
          this.$common.resetField('addform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 修改
    editMin () {
      let params = this.$common.forData(this.editform)
      this.$axios.post('equipment_model/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.editAdmin = false
          var that = this
          this.$common.resetField('editform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 删除
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('equipment_model/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取详情
    getDetail (scope) {
      // console.log(scope)
      this.DetailForm = this.$common.forData2(scope.row,this.DetailForm)
      this.Detail = true
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select,
  ::v-deep .el-date-editor{
    width: 100% !important;
  }
  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
</style>
